<template>
    <div>
        <v-dialog
            v-model="progress"
            persistent
            width="350"
        >
            <v-card min-height=100 >
                <v-card-text class="pt-5 teal--text text--darken-1 text-body-2 font-weight-bold">
                    <span class="d-block text-center">ファイルをアップロードしています。</span>
                    <span class="d-block text-center">{{ uploadStatus }}</span>
                    <span class="d-block text-center">確認済み:{{ finishedCnt }}個 / アップロード予定:{{ uploadCnt }}個</span>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        class="mb-0 mt-2"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-alert
            color="red"
            type="error"
            v-if="error == null || error.length != 0"
        >
            <ul>
                <li
                    v-for="(item, index) in error"
                    :key="'error' + index">
                    {{ item }}
                </li>
            </ul>
        </v-alert>
        <p class="text-h6 font-weight-bold teal--text text--darken-1">
            住戸部分の計算結果PDFのアップロード
        </p>
        <v-row>
            <v-col cols=12>
                <v-alert outlined color="green" height=200>
                    <p
                        class="pa-5 teal--text text--darken-1 text-body-2 font-weight-bold"
                    >
                        エネルギー消費性能計算プログラムの計算結果PDF を 住宅タイプのファイル数分 アップロードして下さい。
                    </p>
                    <v-row justify="center" class="mx-6">
                        <v-alert outlined  width=100% color="grey" class="pa-1">
                            <vue-dropzone ref="myVueDropzone" id="dropzone"
                            :options="dropzoneOptions"
                            :useCustomSlot="true"
                            @vdropzone-files-added="filesAdded"
                            @vdropzone-success-multiple="fileUploaded"
                            @vdropzone-error-multiple="fileUploadFailed"
                            style="background-color: #f5f5f5;"
                            >
                                <div class="teal--text text--darken-1 text-body-2 font-weight-bold ">
                                    ファイルをここにドラッグ&ドロップまたは<span class="text-decoration-underline">参照</span>
                                </div>
                            </vue-dropzone>
                        </v-alert>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <div v-if="isHouseholdInfoImported && fileNames.length > 0">
            <span class="text-body-2 font-weight-bold teal--text text--darken-1">
                以下のファイルは読み込み済みです。
            </span>
            <v-row>
                <v-col cols=12>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td
                                        class="text-body-2 font-weight-medium teal--text text--darken-1"
                                        v-for="(fileName, index) in fileNames"
                                        :key="index"
                                    >
                                    <v-hover v-slot="{ hover }">
                                        <div>
                                            {{ fileName }}
                                            <v-btn
                                                v-if="hover"
                                                icon
                                                color="red"
                                                x-small
                                                dark
                                                @click="deleteHouseholdFileName(fileName)">
                                                <v-icon>mdi-close-thick</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-hover>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <div class="teal--text text--darken-1 pt-5">
                <span class="text-h6 font-weight-bold">住戸リスト</span><br/>
                <span class="text-body-2 font-weight-bold">
                    住戸の総数{{ householdsList.length }}戸分の「住戸の存する階」「住戸の番号」「住宅タイプの名称」を入力して下さい。
                </span>
            </div>
            <v-row>
                <v-col cols=8 sm=12 md=7 lg=8>
                    <v-data-table
                        dense
                        class="border_tbl"
                        :items="householdsList"
                        :footer-props="{
                            showFirstLastPage: true,
                            itemsPerPageOptions : [50,100],
                            itemsPerPageText : ''
                        }"
                        @update:page="updateCurrentIndex"
                        >
                        <template v-slot:body="props">
                            <thead>
                                <tr>
                                    <th style="max-width:3.5em">番号</th>
                                    <th style="max-width:6.5em">住戸の存する階</th>
                                    <th style="max-width:6.5em">住戸の番号</th>
                                    <th>住宅タイプの名称</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(household, index) in props.items"
                                    :key="index">
                                    <td class="text-right" style="max-width:3.5em">{{ householdsList.indexOf(household) + 1 }}</td>
                                    <td style="max-width:6.5em">
                                        <v-text-field
                                            v-model="household.floor"
                                            dense
                                            color="grey"
                                            @input="updateHouseHoldsList(householdsList)"
                                            :error-messages="requiredErrors('householdsList.$each['+ parseInt(index + currentStartIndex) +'].floor','住戸の存する階を入力して下さい。')"
                                            @blur="$v.householdsList.$each[parseInt(index + currentStartIndex)].floor.$touch()"
                                        ></v-text-field>
                                    </td>
                                    <td style="max-width:6.5em">
                                        <v-text-field
                                            dense
                                            v-model="household.householdNum"
                                            color="grey"
                                            @input="updateHouseHoldsList(householdsList)"
                                            :error-messages="requiredErrors('householdsList.$each['+ parseInt(index + currentStartIndex) +'].householdNum','住戸の番号を入力して下さい。')"
                                            @blur="$v.householdsList.$each[parseInt(index + currentStartIndex)].householdNum.$touch()"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-select
                                            dense
                                            v-model="household.householdType"
                                            :items="householdsTypeList"
                                            :placeholder="household.householdType"
                                            color="grey"
                                            @input="updateHouseHoldsList(householdsList)"
                                            :error-messages="requiredErrors('householdsList.$each['+ parseInt(index + currentStartIndex) +'].householdType','住宅タイプの名称を入力して下さい。')"
                                            @blur="$v.householdsList.$each[parseInt(index + currentStartIndex)].householdType.$touch()"
                                            class="text-body-2"
                                        ></v-select>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols=4 sm=12 md=5 lg=4 class="uq_listCsv">
                    <v-alert outlined color="green">
                        <p class="text-body-2 font-weight-bold teal--text text--darken-1 text-center">
                            表計算ソフトなどで別途入力した<br/>住戸リストCSVを利用できます。
                        </p>
                        <a href="/files/householdListTemplate.csv" download="住戸リストサンプル.csv">住戸リストCSVのサンプル</a>
                        <v-file-input accept=".csv" ref="householdListUploader" class="d-none" @change="readHouseholdList"></v-file-input>
                        <v-btn
                            block
                            color="#025F59"
                            class="my-3 ml-auto white--text font-weight-bold"
                            @click="$refs.householdListUploader.$el.querySelector('input').click()"
                        >
                            <v-spacer></v-spacer>
                             住戸リストCSVの読込
                            <v-spacer></v-spacer>
                            <v-icon large color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-btn
                            @click="exportCSV"
                            block
                            color="#4ACAA0"
                            class="mb-3 white--text font-weight-bold"
                        >
                            <v-spacer></v-spacer>
                            住戸リストCSVの保存
                            <v-spacer></v-spacer>
                            <v-icon large color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
        <v-row class="pl-5 pr-5">
            <v-btn
                to="basic-info"
                rounded-lg
                color="#A0A0A0"
                width="150"
                height="60"
                class="mt-15 white--text font-weight-bold text-h6"
            >
                <v-icon large>mdi-chevron-left</v-icon>
                <v-spacer></v-spacer>
                戻る
                <v-spacer></v-spacer>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!isHouseholdInfoImported"
                @click="toCommonAreaUpload"
                color="#025F59"
                width="300"
                height="60"
                class="mt-15 ml-5 white--text font-weight-bold text-h6"
            >
                共用部分のアップロード
                <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'
// Jobの状態確認の待ち時間用
import { sleep } from '@/util'
import { required } from "vuelidate/lib/validators"
import { JobStatus } from '@/mixins/JobStatus'

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    mixins: [JobStatus],
    data() {
        return {
            //Dropzone.js Options
            //See: https://docs.dropzone.dev/configuration/basics/configuration-options
            dropzoneOptions:{
                url: '/api/household/upload',
                acceptedFiles: '.pdf',
                // 同時アップロード
                uploadMultiple: true,
                parallelUploads: 5,
                includeStyling: false,
                previewsContainer: false,
                // ファイルをserver側に送信する前の処理
                accept: this.acceptPdf,
                //自動的にキューに追加しない
                autoQueue : false
            },
            // 進行中表示の有無
            progress:false,
            // アップロードするファイルのカウント
            uploadCnt: 0,
            // アップロード済みファイルのカウント
            finishedCnt: 0,
            // アップロード状態
            uploadStatus: '',
            // 住戸リストの現在のスタートインデックス
            currentStartIndex: 0,
        }
    },
    // バリデーションルール
    validations: {
        householdsList: {
            $each: {
                floor: { required },
                householdNum: { required },
                householdType: { required },
            }
        }
    },
    mounted() {
        this.clearError()
        // 住棟全体の住戸数によって住戸リストを作成する
        this.createHouseholdsListByHouseholdCnt()
    },
    computed: {
        ...mapGetters('basicInfo', {
            // 基本情報オブジェクト
            basicInfo:'getBasicInfo',
        }),
        ...mapGetters('fileupload', {
            error: 'getError',
            householdsTypeList:'getHouseholdsTypeList',
            // 住戸リスト
            householdsList:'getHouseholdsList',
            // 読込したファイル名
            fileNames:'getFileNames',
            // 住戸情報のインポートステート
            isHouseholdInfoImported:'isHouseholdInfoImported',
        }),
        // 必須エラーメッセージを返す
        requiredErrors () {
            return (input, msg) => {
                const errors = []
                if (!eval("this.$v."+ input).$dirty) return errors
                !eval("this.$v."+ input).required && errors.push(msg)
                return errors
            }
        }
    },
    methods: {
        ...mapActions('fileupload', {
            getHouseholdTypes:'getHouseholdTypes',
            updateHouseHoldsList: 'updateHouseHoldsList',
            addError: 'addError',
            setError: 'setError',
            clearError: 'clearError',
            clearHouseHoldsList: 'clearHouseHoldsList',
            importHouseHoldsList: 'importHouseHoldsList',
            setHouseholdInfoImported: 'setHouseholdInfoImported',
            appendHouseholdTypes: 'appendHouseholdTypes',
            deleteFile: 'deleteFile',
            createHouseholdsListByHouseholdCnt: 'createHouseholdsListByHouseholdCnt',
            appendHouseholdJobId: 'appendHouseholdJobId',
        }),
        // APIにファイルを読み込む前に重複チェックする
        acceptPdf(file, done) {
            this.progress = true
            this.clearError()
            this.uploadCnt = this.$refs.myVueDropzone.dropzone.files.length
            this.finishedCnt = 0

            // ファイルの重複チェック
            const dupitem = this.fileNames.find( el => el === file.name )
            if (dupitem) {
                done("duplicate")
                this.addError("ファイル名が重複しています。")
            } else {
                done()
            }
        },
        // ファイルドロップの検知
        filesAdded(files){
            //エラー情報を消す
            this.clearError()

            // ステータスメッセージ更新
            this.uploadStatus = "(準備中)"

            // ファイルの重複チェック
            files.forEach(file => {
                if (this.fileNames.find( el => el === file.name)) {
                    this.setError("ファイル名が重複しています。")
                }
            });

            //ファイル追加直後は、以下の理由でキューに追加できない。
            //1. accept処理が終わっていない
            //2. 引数のfilesはDropzone.jsの内部形式になっていない
            const dz = this.$refs.myVueDropzone.dropzone
            setTimeout(()=>{
                const files = dz.getAddedFiles()
                if (files.length > 0) {
                    const nextfiles = files.slice(0, 5)
                    this.uploadStatus = "(アップロード中...)"
                    dz.enqueueFiles(nextfiles)
                }
            }, 1000)
        },
        // ファイル読み込み後の処理
        async fileUploaded(files, jobId) {
            // job実行結果の確認
            const processingStatus = ['unknown','processing']
            let result = ''
            do {
                let response = await this.checkJobStatus({type: "household", job_id: jobId})
                result = response['job_status']
                if (processingStatus.includes(result)) {
                    // 3秒待ち
                    await sleep(3000)
                    // 確認が長くなるのでメッセージを確認中に変更しておく
                    this.uploadStatus = "(確認中...)"
                }
            } while (processingStatus.includes(result))

            // job実行結果の取得
            if (result == 'finished') {

                // キューにファイルを追加する
                const dz = this.$refs.myVueDropzone.dropzone
                const nextfiles = dz.getAddedFiles().slice(0, 5)
                if (nextfiles.length > 0) {
                    this.uploadStatus = "(アップロード中...)"
                    dz.enqueueFiles(nextfiles)
                } else {
                    this.progress = false
                }

                this.appendHouseholdJobId({
                    householdJobId: jobId
                })

                // 結果の取得
                const household_types = await this.getHouseholdTypes(jobId)
                this.appendHouseholdTypes(household_types)

                this.finishedCnt += household_types.length

                if(this.finishedCnt == this.uploadCnt){
                    this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
                }
                this.setHouseholdInfoImported(true)
            } else {
                //error
                this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
                if (result == 'error') {
                    const fnames = files.map(function (el) { return el.name }).join(', ')
                    this.addError("ファイルの内容が確認が出来ませんでした。以下に不適切なファイルが含まれているかご確認ください。" + fnames)
                }
                else {
                    this.addError("タイムアウトしました。しばらく間を空けてから再度お試しください。")
                }
                this.progress = false
            }
        },
        // ファイル読み込み失敗の処理
        fileUploadFailed(files, message, xhr){
            this.progress = false
            this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
            if (message == "You can't upload files of this type."){
                this.setError("PDFファイルを指定してください。")
            }else if (xhr && xhr.status == 500) {
                this.setError("エラーが発生しました。しばらくしてからもう一度お試し下さい。")
            }
        },
        // 住戸リストをCSVで読み込む
        readHouseholdList(file) {
            if (file) {
                let reader = new FileReader()
                reader.readAsText(file)
                reader.onload = () => {
                    let lines = reader.result.split("\n")
                    // ヘーダカウント確認する
                    let headerColCnt = lines[0].split(",").length
                    if (headerColCnt == 4) {
                        // CSVの一行目のヘーダを消す
                        lines.shift()
                        let householdTypes = []
                        // 空の行を削除
                        lines = lines.filter(x => x != "")
                        //インポートされたCSVの住戸数と基本情報の住戸数が一致するか確認
                        if (lines.length == this.basicInfo.householdTotalNum) {
                            this.clearHouseHoldsList()
                            lines.forEach(line => {
                                const cols = line.split(",")
                                if(cols[0] !== ""){
                                    householdTypes.push(cols[3].replace(/(\r\n|\n|\r)/gm, ""))
                                    const household_info = {
                                        floor: cols[1],
                                        householdNum: cols[2],
                                        householdType: householdTypes[householdTypes.length - 1]
                                    }
                                    this.householdsList.push(household_info)
                                }
                            })
                            this.importHouseHoldsList(this.householdsList)
                        } else {
                            this.setError("住戸の総数が基本情報と一致しません。")
                        }
                    } else {
                        this.setError("住戸リストCSVの読込ができませんでした。アップロードしたファイルを確認して下さい。")
                    }
                }
                this.$refs.householdListUploader.$el.querySelector('input').value = ''
            }
        },
        // 住戸リストCSVの保存
        exportCSV() {
            //ダウンロードするCSVファイル名を指定する
            const filename = "住戸リスト.csv"
            //CSVデータ
            let csv = '行番号,住戸の存する階,住戸の番号,住宅タイプの名称\n'
            this.householdsList.forEach(function callback(row, index) {
                csv += index+1 + "," +
                    row.floor + "," +
                    row.householdNum + "," +
                    row.householdType + "\n"
            })
            //BOMを付与する（Excelでの文字化け対策）
            const bom = new Uint8Array([0xef, 0xbb, 0xbf])
            //Blobでデータを作成する
            const blob = new Blob([bom, csv], { type: "text/csv" })

            //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, filename)
            //その他ブラウザ
            } else {
                //BlobからオブジェクトURLを作成する
                const url = (window.URL || window.webkitURL)
                const objectUrl = url.createObjectURL(blob)
                //ダウンロード用にリンクを作成する
                const download = document.createElement("a")
                //リンク先に上記で生成したURLを指定する
                download.href = objectUrl
                //download属性にファイル名を指定する
                download.download = filename
                //作成したリンクをクリックしてダウンロードを実行する
                download.click()
                //createObjectURLで作成したオブジェクトURLを開放する
                url.revokeObjectURL(objectUrl)
            }
        },
        // 共用部分のアップロード画面に遷移
        toCommonAreaUpload() {
            //エラー情報を消す
            this.clearError()
            
            this.$v.householdsList.$touch()
            if(!this.$v.householdsList.$invalid) {
                const error_household_type = []
                //住宅リストの住宅タイプの名称がアップロードされた住宅タイプの名称と一致しない場合は、住宅タイプの名称をエラー配列に追加
                this.householdsList.forEach(value => {
                    if (!this.householdsTypeList.includes(value.householdType)) {
                        error_household_type.push(value.householdType)
                    }
                })

                if (error_household_type.length == 0){
                    this.$router.push('common-area-upload')
                } else{
                    this.setError("住宅リストの住宅タイプの名称にアップロードされていない住宅タイプの名称が選択されています。")
                }
            }
        },
        // 住戸部分のPDFファイル名を削除する処理
        deleteHouseholdFileName(filename){
            this.deleteFile(filename)
            if (this.fileNames.length == 0) {
                this.$v.householdsList.$reset()
            }
        },
        // 住戸リストの現在ページによってスタートインデックスを更新する
        // バリデーション用
        updateCurrentIndex(page) {

            this.currentStartIndex = (page - 1) * 50
        }
    }
}
</script>
<style>
.dropzone {
  min-height: 0px !important;
  border: none;
}
.dropzone .dz-message{
  margin: 0 !important;
}
.border_tbl th, .border_tbl td {
  border: 1px solid gray;
  padding: 0 8px !important;
}
.v-expansion-panels{
    max-width: 500px;
}
.v-expansion-panel-header__icon {
    margin-left: 0px !important;
}
.progress {
    position: fixed;
    top:0%;
    left: 0%;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}
.uq_listCsv .v-alert__content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>